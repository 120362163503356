<template>
	<div class="bg-gray">
		<div class="container eurail-search-result">
			<div class="search">
				<div class="city-box">
					<div class="mb5 mt10 pl5">出发城市</div>
					<div>
						<el-input type="text" name="name" value="巴黎(FRPAR)" class="form-control"></el-input>
					</div>
					<div class="mb5 mt10 pl5">到达城市</div>
					<div>
						<el-input type="text" name="name" value="巴塞尔(CHAJP)" class="form-control"></el-input>
					</div>
				</div>
				<div class="translate-box">
					<span class="iconfont icon-updowntranslate"></span>
				</div>
				<div class="search-box">
					<div>
						<div class="startdate">
							<div class="mb5 mt10 pl5">出发日期</div>
							<div>
								<el-date-picker v-model="value1" type="date" placeholder="选择日期" style="width:100%">
								</el-date-picker>
							</div>
						</div>
						<div class="backdate">
							<div class="mb5 mt10 pl5">返回日期</div>
							<div>
								<el-date-picker v-model="value1" type="date" placeholder="选择日期" style="width:100%">
								</el-date-picker>
							</div>
						</div>
						<div class="time-quantum">
							<div class="mb5 mt10 pl5">时间</div>
							<div>
								<el-select>

								</el-select>
							</div>
						</div>
					</div>
					<div>
						<div>
							<el-dropdown :hide-on-click="false">
								<div>
									<div class="mt15 mb5">受客人数</div>
									<div class="people-btn">
										<div class="fn lb-box">
											<span>1</span>
											<span>成人</span>
											<span>1</span>
											<span>儿童</span>
											<span>1</span>
											<span>青年</span>
											<span>1</span>
											<span>长者</span>
											<span class="iconfont icon-xiala icon-xiala2"></span>
										</div>
									</div>
								</div>
								<el-dropdown-menu slot="dropdown" style="width:400px" class="choose-boxs">
									<div class="pover-box ft14">
										<div class="pt15 pb15 b-line text-c">
											<span class="iconfont icon-sub"></span>
											<div class="i-box">
												<div>
													<span class="num">1</span><span class="ft12 gray9">名青年</span></div>
											</div><span class="iconfont icon-add"></span>
										</div>
										<div class="children text-c">
											<span class="iconfont icon-sub"></span>
											<div class="i-box">
												<div><span class="num">1</span>
													<span class="ft12 gray9">名儿童</span>
												</div>
											</div><span class="iconfont icon-add"></span>
										</div>
										<div class="pt15 pb15 b-line text-c">
											<span class="iconfont icon-sub"></span>
											<div class="i-box">
												<div><span class="num">1</span>
													<span class="ft12 gray9">名青年</span></div>
											</div><span class="iconfont icon-add"></span>
										</div>
										<div class="pt15 pb15 b-line text-c"><span class="iconfont icon-sub"></span>
											<div class="i-box">
												<div><span class="num">1</span><span class="ft12 gray9">名长者</span></div>
											</div><span class="iconfont icon-add"></span>
										</div>
									</div>
								</el-dropdown-menu>
							</el-dropdown>



							<button type="button" class="search-btn mr15">搜索</button>
							<span class="y-r" @click="openModel(1)">年龄规则</span><span class="iconfont icon-point" @click="openModel(1)"></span>
							<span class="y-r problem" @click="openModel(2)">常见问题</span><span class="iconfont icon-point" @click="openModel(2)"></span>
						</div>
					</div>


				</div>
			</div>
			<div class="clearfix already-choose-box">
				<div class="fl ft16">
					<span class="themem-color">已选:</span>
					<span class="fb">普罗旺斯</span>
					<span class="iconfont icon-arrow fb"></span>
					<span class="fb">法兰克福</span>
					<span class="fb ml10">出发日期:2018-11-10</span>
					<span class="fb">星期六</span>
					<span class="ft12 gray9 ml20">出发抵达时间均为当地时间</span>
				</div>
				<div class="fr choose-day ft16"><span>前一天</span><span class="ml10">后一天</span></div>
			</div>
			<div class="clearfix price-list">
				<div class="fl">
					<span>价格</span>
					<span class="iconfont icon-up"></span>
					<span class="available-time">用时时长</span>
					<span class="iconfont icon-up"></span>
					<span class="available-time">早晚班次</span>
					<span class="iconfont icon-up"></span>
				</div>
				<div class="fr">
					<span>出票费用:</span>
					<span>100￥/人/段</span>
				</div>
			</div>
			<div class="panel-group">
				<div class="panel panel-default">
					<div class="panel-heading">
						<div class="pl15 pt20 pb20">
							<div class="site">
								<span class="iconfont icon-site"></span>
								<span class="ft16 mt5">TGP 9890</span>
							</div>
							<div class="part-time ft18">
								<div>
									<span class="fb start-time">08:47</span>
									<span class="iconfont icon-arrowt"></span>
									<span class="fb end-time">18:47</span>
								</div>
								<div class="gray9 ft12">
									<span class="mr2">AEROPORT</span>
									<span class="mr2">CDG</span>
									<span class="mr2">ZTGV</span>
									<span class="empty"></span>
									<span class="mr2">PARIS</span>
									<span>NORD</span>
								</div>
							</div>
							<div class="gray9 air-time">
								搭乘时间：10h
							</div>
							<div class="transfer" @click="openModel(3)">
								<span class="transfer-time">需换乘1次</span>
							</div>
							<div class="jiage">
								<div class="price ft12"><span>￥</span><span class="ft22 fb">3400</span><span class="gray6">起</span></div>
								<div data-toggle="modal" data-target=".price-message"><span class="gray6 sum-price">6人总价</span></div>
							</div>
							<h4 class="panel-title">
								<a class="booking-ticket">
									订票
									<span class="iconfont icon-arrow-down"></span>
								</a>
							</h4>

						</div>
					</div>

					<div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
						<div class="panel-body site-body">
							<div class="site-content clearfix">
								<div class="fl h30">
									<div class="v-center">
										<span class="mr56">一等座</span>
										<span class="buyticket-rule" @click="openModel(4)">购票/退票规则&gt;</span>
									</div>

								</div>
								<div class="fr h30">
									<div class="v-center book">
										<span class="price ft12">￥</span>
										<span class="price ft22 fb">3400</span>
										<a href="#" class="designate">选定</a>
									</div>
								</div>
							</div>
							<div class="site-content clearfix">
								<div class="fl h30">
									<div class="v-center">
										<span class="mr56">一等座</span>
										<span class="buyticket-rule">购票/退票规则&gt;</span>
									</div>

								</div>
								<div class="fr h30">
									<div class="v-center book">
										<span class="price ft12">￥</span>
										<span class="price ft22 fb">3400</span>
										<a href="#" class="designate">选定</a>
									</div>
								</div>
							</div>

							<div class="site-content clearfix">
								<div class="fl h30">
									<div class="v-center">
										<span class="mr56">一等座</span>
										<span class="buyticket-rule">购票/退票规则&gt;</span>
									</div>

								</div>
								<div class="fr h30">
									<div class="v-center book">
										<span class="price ft12">￥</span>
										<span class="price ft22 fb">3400</span>
										<a href="#" class="designate">选定</a>
									</div>
								</div>
							</div>

							<div class="site-content clearfix">
								<div class="fl h30">
									<div class="v-center">
										<span class="mr56">一等座</span>
										<span class="buyticket-rule">购票/退票规则&gt;</span>
									</div>

								</div>
								<div class="fr h30">
									<div class="v-center book">
										<span class="price ft12">￥</span>
										<span class="price ft22 fb">3400</span>
										<a href="#" class="designate">选定</a>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<desModal :titleRight="open==1" title="年龄规定" class="person-modal" @clickDelAction="hideModel">
			<div class="age-rule">
				<p>当您搜索点对点火车票时，必须按照以下信息来选择所需车票。</p>
				<p>成人：26-59岁</p>
				<p>老人：60岁以上</p>
				<p>青年：25岁以下</p>
				<p>儿童：在欧洲，各国对于儿童的年龄界定是不同的。详见下表。</p>
				<table>
					<tbody>
						<tr>
							<th class="th01">儿童的年龄范围</th>
							<th class="text-center">Countries（国家）</th>
						</tr>
						<tr>
							<td class="th01">4-11</td>
							<td>France, Croatia, Serbia, Bosnia, Greece, Italy, Macedonia, Netherlands, Poland, Romania, Spain. （法
								国、克罗地亚、塞班、波斯尼亚、希腊、意大利、马其顿、荷兰、波兰、罗马尼亚、西班牙）</td>
						</tr>
						<tr>
							<td class="th01">4-12</td>
							<td>Czech Republic（捷克）</td>
						</tr>
						<tr>
							<td class="th01">4-16</td>
							<td>Norway（挪威）</td>
						</tr>
						<tr>
							<td class="th01">5-12</td>
							<td>Portugal（葡萄牙）</td>
						</tr>
						<tr>
							<td class="th01">6-11</td>
							<td>Belgium, Bulgaria, Luxembourg, Slovakia, Slovenia.（比利时、保加利亚、卢森堡、斯洛伐克、斯洛文尼亚）</td>
						</tr>
						<tr>
							<td class="th01">6-13</td>
							<td>Hungary.（匈牙利）</td>
						</tr>
						<tr>
							<td class="th01">6-14</td>
							<td>Germany, Sweden（德国、瑞典）</td>
						</tr>
						<tr>
							<td class="th01">6-15</td>
							<td>Austria, Denmark, Switzerland.（澳大利亚、丹麦、瑞士）</td>
						</tr>
						<tr>
							<td class="th01">6-16</td>
							<td>Finland.（芬兰）</td>
						</tr>
					</tbody>
				</table>
				<p>婴儿：4岁或6岁以下。不占座婴儿可免费随成人同行。</p>
				<p>当您选择跨国境的铁路，儿童票只适用于4－11岁的孩子。</p>
				<p>当没有适用的老人票、青年票或儿童票时，您必须以成人票价订购。</p>
			</div>
		</desModal>
		<desModal :titleRight="open==2" title="常见问题" class="FAQ-modal" widthNum="600px" @clickDelAction="hideModel">
			<div class="age-rule ft14">
				<dl>
					<dt><span class="iconfont icon-question"></span>如果我有通票，是所有的火车都可以乘坐吗？</dt>
					<dd>欧洲火车绝大部分为国有铁路，持通票可以乘坐，但是欧洲各地也有一些属于私人经营的火车或登山铁路、缆车。</dd>
					<dt class="mt10"><span class="iconfont icon-question"></span>持火车票可不可以搭乘市内交通？</dt>
					<dd>持欧洲火车票可以乘坐城市与城市之间的国有火车，不适用于市内交通如地铁、电车、巴士等。但瑞士通票除了可以乘坐国有火车以外，还可以乘坐瑞士境内大部分游船、邮政大巴。</dd>
					<dt class="mt10"><span class="iconfont icon-question"></span>活期通票的天数如何计算？</dt>
					<dd>在有效期内，哪天乘坐火车，则在通票上填写当天日期，在当天内可以无限次乘搭火车。</dd>
				</dl>

			</div>
		</desModal>
		<desModal :titleRight="open==4" title="购票及退票规则" class="FAQ-modal" widthNum="600px" @clickDelAction="hideModel">
			<div class="modal-body">
				<div class="ft16 mt15 mb15">1.第一程:TER 80157</div>
				<div>
					<div class="mt15 mb15 ft16 pl5">
						<span class="iconfont icon-triangle icon-sanjiaoxing-youbian gray9 mr10"></span>
						<span>购票规则</span>
					</div>
					<div class="ft14 pl24 mb20">
						Advance purchase recommended-Can be combined with a Leisure Week
						End Fare-Child up to 11 years inclusive
					</div>
					<div class="mt15 mb15 ft16 pl5">
						<span class="iconfont icon-triangle icon-sanjiaoxing-youbian  gray9 mr10"></span>
						<span>退票规则</span>
					</div>
					<div class="ft14 pl24 mb20">
						Refunds/exchanges are subject to an adminstractive fee in addition to the following conditions.Refunds.if ticket
						not used.refundable up to 33 days before train departure.Then a 5EUR fee applies up to 3 days before train
						departure.Then a maximum 15 EUR fee applies up to train exchangeable up to 33 days befoore train departure.Then a
						5 EUR fee applies up to 3 days before train departure.Then a maximum 15 EUR fee
						applies up to train departure.Therefter no exchange allowed.Passenger must pay the difference in price for next
						avaliable fare in same or higher class of service.
					</div>
				</div>
				<div class="ft16 mt15 mb15">1.第二程:TER 80157</div>
				<div>
					<div class="mt15 mb15 ft16 pl5">
						<span class="iconfont icon-triangle icon-sanjiaoxing-youbian  gray9 mr10"></span>
						<span>购票规则</span>
					</div>
					<div class="ft14 pl24 mb20">
						Advance purchase recommended-Can be combined with a Leisure Week
						End Fare-Child up to 11 years inclusive
					</div>
					<div class="mt15 mb15 ft16 pl5">
						<span class="iconfont icon-triangle icon-sanjiaoxing-youbian  gray9 mr10"></span>
						<span>退票规则</span>
					</div>
					<div class="ft14 pl24 mb20 route-bor">
						Refunds/exchanges are subject to an adminstractive fee in addition to the following conditions.Refunds.if ticket
						not used.refundable up to 33 days before train departure.Then a 5EUR fee applies up to 3 days before train
						departure.Then a maximum 15 EUR fee applies up to train exchangeable up to 33 days befoore train departure.Then a
						5 EUR fee applies up to 3 days before train departure.Then a maximum 15 EUR fee
						applies up to train departure.Therefter no exchange allowed.Passenger must pay the difference in price for next
						avaliable fare in same or higher class of service.
					</div>
				</div>
				<div>
					<div>
						<div class="mt15 mb10 ft16 pl5">
							<span>购票特别说明</span>
						</div>
					</div>
					<ol class="status" start="1">
						<li>1.请仔细阅读上述购买与退票规则。</li>
						<li>2.通常情况下，可以查询和预订两个月以内的欧洲火车。</li>
						<li>3.预订价格为查询人数的总和。</li>
						<li>4.为确保购票顺利，在预订完成后，请尽快完成支付。</li>
						<li>5.点到点的车票，通常已经包括了座位，不再需要另外预订。</li>
					</ol>
				</div>
			</div>
		</desModal>
		<desModal :titleRight="open==3" title="换成信息" class="translate-message" widthNum="400px" @clickDelAction="hideModel">
			<div class="modal-body">
				<div class="flight-message mt10 mb10">
					<div class="start-day">
						<div class="ft14 mt10 mb10"><span>11月04日 星期天</span></div>
						<div class="ft16 mt10 mb10"><span>08:47</span></div>
					</div>
					<div class="middle-message ml17 mr17">
						<div class="mt5 mb5"><span>TGP 9890</span></div>
						<div class="middle-line"></div>
						<div class="hour-time"><span class="iconfont icon-clock"></span><span class="hour">10h</span></div>
					</div>
					<div class="end-day">
						<div class="mt10 mb10"><span class="ft14">11月04日 星期天</span></div>
						<div class="mt10 mb10"><span class="ft16">08:47</span></div>
					</div>
				</div>
				<div class="gray9">
					<span class="modal-line"></span>
					<span class="iconfont icon-translate"></span>
					<span class="detail">马赛 换乘 间隔34m</span>
					<span class="modal-line"></span>
				</div>
				<div class="flight-message mt20 mb10">
					<div class="start-day">
						<div class="ft14 mt10 mb10"><span>11月04日 星期天</span></div>
						<div class="ft16 mt10 mb10"><span>08:47</span></div>
					</div>
					<div class="middle-message ml17 mr17">
						<div class="mt5 mb5"><span>TGP 9890</span></div>
						<div class="middle-line middle-line2"></div>
						<div class="hour-time"><span class="iconfont icon-clock"></span><span class="hour">10h</span></div>
					</div>
					<div class="end-day">
						<div class="mt10 mb10"><span class="ft14">11月04日 星期天</span></div>
						<div class="mt10 mb10"><span class="ft16">08:47</span></div>
					</div>
				</div>

			</div>
		</desModal>
	</div>
</template>

<script>
	import desModal from '../../components/desModal.vue';
	export default {
		name: 'SelectPerson',
		components: {
			desModal
		},

		data() {
			return {
				options: [{
					value: '选项1',
					label: '护照'
				}],
				value: '',
				value1: '',
				value2: '',
				radio: '',
				open: "",
				
			};
		},
		methods: {
			openModel(e) {
				this.open = e;
			},
			hideModel() {
				this.open = "";
			}
		}
	};
</script>

<style lang="scss">
	@import '../../style/Europe/Europe.scss';
</style>
